.maskSenhaRecuperacao {
    display: flex;
    flex-direction: row;
    background-color: #f3f3f3;
    border: none;
    border-radius: 20px;
    height: 30px;
    width: 334px !important;
    text-align: center;
}

input[type=date]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    display: none;
}

input[type=date]:invalid::-webkit-datetime-edit {
    color: #757589;
}

@media(max-width:369px) {
    .maskSenhaRecuperacao {
        width: 260px !important;
    }
}