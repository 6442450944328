.mask {
    text-align: left;
    width: 293px;
    height: 34px;
    border-radius: 1rem;
    border: none;
    margin-right: 3px;
    margin-left: -100px;
    padding-left: 15px;
    background-color: #f3f3f3;
    grid-area: i;
}

@media (max-width: 610px) {
    .mask {
        position: relative;
        margin-left: 0;
        margin: 0;
        width: 100%;
        justify-self: center;
        align-self: center;
    }
}
