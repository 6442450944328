/* form{
    flex-direction: row;
    display: flex !important;
    position: relative !important;
    padding-bottom: 100px !important;
    width:100% !important ;
   
   
   
}

.radio{
    margin-left: 15px !important;
    position: relative !important;
    text-align: center !important;
}

input{
    text-align:left !important;
}


.horario{
    position: absolute !important;
    bottom:0 !important;
    

}

.btn{
    position: absolute;
    bottom:-4px;
    left:40%
}

@media(max-width:1410px){
        form{
            flex-direction: column;
            width:100%
        }

        .radio{
                text-align:left
        }

        .horario{
            position: absolute;
            top: 46%;
            right:13%;
            
        
        }
        .btn{
            left:38%
        }
}


@media(max-width:1050px){
    

    .radio{
            text-align:left
    }

    .horario{
        position: absolute;
        top: 100%;
        right:0%;
        
        
    
    }
    .btn{
        left:38%
    }
} */

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;

  height: 40px;
  width: 40px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover {
  background: #9faab7;
}
.option-input:checked {
  background: var(--red-color);
}
.option-input:checked::before {
  height: 40px;
  width: 40px;

  position: absolute;
  content: "✔";
  display: inline-block;
  font-size: 26.66667px;
  text-align: center !important;
  line-height: 40px;
}
.option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: var(--red-color);
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}
.option-input.radio {
  border-radius: 50%;
}
.option-input.radio::after {
  border-radius: 50%;
}
