.Modal{
    position: absolute;
    top: 33%;
    width: 20%;
    min-height:288px;
    max-height: 350px;
    left: 50%;
    transform: translate(-50%);
    background-image: linear-gradient(180deg, #ff6362 50%, var(--red-color) 100%);
    box-shadow: 0.2em 0.2em 0.2em rgba(194, 183, 183, 0.897);
    position: absolute;
    height: 26%;
    border-radius:7px;

   
}

.btn{
    background-color: white;
    margin: 10px 10px 45px 10px;
    box-shadow: 0.2em 0.2em 0.6em rgba(163, 146, 146, 0.774);
    font-weight: bold;
}
.btn:hover{
    background-color: aliceblue;
    
}

.btn-fechar{
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 0 10px 0 10px;
    border-radius: 3px;
    border: 0.3px solid red;
    box-shadow: 0.2em 0.2em 0.2em rgba(223, 16, 16, 0.774);
}

.btn-fechar:hover{
    background: #f72323;
    box-shadow: 0.2em 0.2em 0.2em rgba(241, 35, 35, 0.774);
}

.contentModal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.msg:hover{
    font-size:107%;
   transition:  0.2s linear;
}

@media(max-width:1413px){
    .Modal{
        width: 35%;
    }
}

@media(max-width:916px){
    .Modal{
        width: 45%;
    }
}

@media(max-width:660px){
    .Modal{
        width: 60%;
    }
}

@media(max-width:384px){
    .Modal{
        width: 80%;
    }
}

