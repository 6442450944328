.botao {
  text-decoration: none;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  font-weight: bolder;
  background: var(--red-color);
  padding: 9px;
  border-radius: 3px !important;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s linear;
}

.botao:hover {
  color: whitesmoke;
  transform: scale(1.1);
}

@media (max-width: 1499px) {
}
