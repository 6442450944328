.InputMas {
    display: flex;
    flex-direction: row;
    background-color: #f3f3f3;
    border: none;
    border-radius: 20px;
    height: 30px;
    width: 334px !important;
    text-align: center;
}

.termos {
    float: right;
}

.label {
    transition: font-weight 0.03s;
    transition-timing-function: ease;
}

.label:hover {
    font-weight: 600;
    text-decoration: underline rgb(224, 27, 20);
    cursor: pointer;
}

input {
    caret-color: red;
    text-align: center;
}

@media(max-width:369px) {
    .InputMas {
        width: 260px !important;
        font-size: 12px;
    }
}