
.Input{
    display: flex;
    flex-direction: row;
    background-color: #f3f3f3;
    border: none;
    border-radius: 20px;
    height: 30px;
    width: 220px !important;
    text-align: center;
}

.Input2{
    float: right;
    display:flex;
    background-color: #f3f3f3;
    border: none;
    border-radius: 5px;
    height: 30px;
    width: 270px;
    text-align: center;
    font-weight: 600;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: 11.9px;
    margin-right: 19px;

}

@media (max-width: 1634px) {
.Input2{
    margin-right:5px;
}
}