.maskCPFRecuperacao {
    display: flex;
    flex-direction: row;
    background-color: #f3f3f3;
    border: none;
    border-radius: 20px;
    height: 30px;
    width: 334px !important;
    text-align: center;
}

@media(max-width:369px) {
    .maskCPFRecuperacao {
        width: 260px !important;
    }
}