.inputLabel input:not([value=""]) ~ label,
.inputLabel input:focus ~ label {
  color: blue;
  transform: translate(20%, -50%);
  background-color: var(--red-color);
  font-size: 0.8em;
  padding: 0 10px;
  color: #fff;
  border-radius: 2px;
  height: fit-content;
  width: fit-content;
  transition: 0.5s;
}

.inputLabel input:not([value=""]) input,
.inputLabel input:focus input {
  border: 1px solid var(--red-color);
  transition: 0.5s;
}
