.inputMask {
  float: right;
  display: flex;
  background-color: #f3f3f3;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 270px;
  text-align: center;
  font-weight: 600;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 11.9px;
  margin-right: 19px;
  margin-top: 0;
}

@media (max-width: 1634px) {
  .inputMask {
    margin-right: 5px;
  }
}
