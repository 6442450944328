.mask {
    width: 293px;
    height: 34px;
    
    padding-left: 15px;

    text-align: left;
    
    border: none;
    border-radius: 1rem;
    
    background-color: #f3f3f3;
}

@media (max-width: 610px) {
    .mask {
      width: 100%;
      align-self: center;
      justify-self: center;
    }
  }