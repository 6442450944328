
  *, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    background: #fff;
  }
  
  .container {
    height: auto;
    min-height: 775px;
    background: white;
    padding: 2rem 2rem;
    box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
    border-radius: .5rem;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
    


    
   @media(max-width:464px){
       width:80%
   }
   
  }
  
  .bloc-tabs {
    display: flex;
    
   
  }
  .tabs {
  
    padding: 15px;
    text-align: center;
    width: 50%;
    background: var(--red-color);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
    color: white;
    
    font-weight: bolder;
    
  }
  .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .active-tabs  {
    font-size: 17px;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
  }
  
  
  
  button {
    border: none;
  }
  
  .content-tabs {
    flex-grow : 1;
  }
  .content {
    background: #ffff;
    padding: 20px;
    width: 100%;
    height: 10%;
    display: none;
  }
  .content h2 {
    padding: 0px 0 5px 0px;
  }
  .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .content p {
    width: 100%;
    height: 100%;
  }
  .active-content {
    display: block;
  }

@media (max-width: 767px){
  .bloc-tabs{
    overflow: auto;
  }
  .tabs{
    font-size:13px;
  }
  .active-tabs{
    font-size:15px;
  }
}